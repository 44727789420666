// @unocss-include

export function useAccount() {
  const route = useRoute()
  const { t } = useI18n()

  const activeRoute = computed(() => route.path)
  const isActiveRoute = (path: string) => activeRoute.value === path

  const navigation = computed(() => {
    return [
      {
        to: '/account',
        icon: 'i-heroicons:user-circle',
        label: t('general.profile.label'),
        active: false,
      },
      {
        to: '/account/orders',
        icon: 'i-heroicons:shopping-bag',
        label: t('general.orders.label'),
        active: false,
      },
      {
        to: '/account/disputes',
        icon: 'i-heroicons:pencil-square',
        label: t('disputes.page.title'),
        active: false,
      },
      {
        to: '/account/addresses',
        icon: 'i-heroicons:map-pin',
        label: t('general.addresses.label'),
        active: false,
      },
      {
        to: '/account/security',
        icon: 'i-heroicons:lock-closed',
        label: t('general.security.label'),
        active: false,
      },
      {
        to: '',
        label: t('form.sign_out.label'),
        onClick: () => authLogout(),
      },
    ].map((navItem) => {
      navItem.active = isActiveRoute(navItem.to)
      return navItem
    })
  })

  return {
    navigation,
  }
}
